import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function TextEditor({onTranslate, item,privacy=false}) {
  return item && (
    <div>
      <h1 dangerouslySetInnerHTML={{__html:privacy || item["label"]}}/>
      <ReactQuill
        theme="snow"
        value={item["value"]}
        onChange={onTranslate}
      />
    </div>
  );
}
